/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
    
    /* !!! important: thinkg twice before assigning a function to a page class: it gets lost in translation (the page class may change ) !!! */
  var Sage = {
    // All pages
    'common': {
    	init: function() {
    		// JavaScript to be fired on all pages
    		if (window.location.hash !== '' && $('.sidebar_papers').length) {
    			$('.sidebar_papers li a').each(function () {
    				if (this.href.indexOf(window.location.hash) > -1) {
    					$(this).parent().addClass('current');
    				}
    			});
    		}
    		$('.inner_anchor').each(function () {
    			$(this).click(function () {
    				var hash = this.href.split('#')[1];
    				var target_element = $('#' + hash);
    				$('html,body').animate({scrollTop: target_element.offset().top},'slow');
    				$(this).parent().siblings().each(function () {
    					$(this).removeClass('current');
    				});
    				$(this).parent().addClass('current');
    				$('.catname').html('/' + this.innerHTML);
    			});
    		});
			
			/* Gated Dox */
			if ($('.screen').length) {
    			$('.screen').hide();
    			if ($('.enquiery_form_holder').length) {
    				$('.enquiery_form_holder').hide();
    				$('.is_gated').each(function () {
    					$(this).click(function () {
    						var item_id = $(this).data('item');
    						var button_txt = this.innerHTML;
    						$('.enquiery_form_title').html(button_txt);
    						$('#buttontext').val(button_txt);
    						$('.screen').fadeIn();
    						$('.enquiery_form_holder').fadeIn();
    						$('.enquiery_form_holder #itemid').val(item_id);
    					});
    				});
    				$('.enquiery_form_holder .close').click(function () {
    					$('.screen').fadeOut();
    					$('.enquiery_form_holder').fadeOut();
    				});
    			}
    		}

            /* Model Carousel */
            if ($('.cycle-carousel').length) {	
                $('.cycle-carousel').cycle();
                    $('.navstrip .thumb').each(function () {
                            $(this).click(function () {
                                    $('.navstrip .thumb').removeClass('current');
                                    $(this).addClass('current');
                                    var target_slide_index = this.id.replace(/goto_slide_/,'');
                                    $('.cycle-slideshow').cycle('goto', target_slide_index);
                            });
                    });
            }
    		/* Animations */
    		var animated = document.querySelectorAll('.animated');
  			Array.apply(null, animated).forEach(function (el,n) {
  				if (el.getAttribute('data-animstyle') !== '') {
  				var waypoint = new Waypoint({
  					element: el,
  					offset: 'bottom-in-view',
  					handler: function(direction) {
  						var animation_type = this.element.getAttribute('data-animstyle');
  						this.element.classList.add(animation_type);
  					}
  				});
  				}
  			});
  			 $(".toggle_sound").click( function (){
  				 var video_el = $(this).parent().find('video');
  	    		 $(video_el).prop('muted', !$(video_el).prop('muted'));
  	    		 $(this).find('img').each(function (i, el) {
  	    			 if ($(el).is(':visible')) {
  	    				 $(el).hide();
  	    			 }
  	    			 else {
  	    				 $(el).show();
  	    			 }
  	    		 });
  	    	  });
            inform_crm();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
    	 
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'models': {
    	init: function () {

    	}
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact_us' : {
    	init: function () {
    		$('.dist_details').hide();
    		var pins = $('.location_pin');
    		pins.each(function (i, el) {
    			$(el).find('.dist_pin').click(function () {
    				var dist_details = $(el).find('.dist_details');
    				if (dist_details.is(':visible')) {
    					dist_details.fadeOut();
    					$('.wrap').css({'z-index':'0'});
    				}
    				else {
    					pins.find('.dist_details').fadeOut();
    					$('.wrap').css({'z-index':'-1'});
    					dist_details.fadeIn();
    				}
    			});
    		});
    	}
    },
    'category' : {
    	init: function () {
    		
    	}
    },
    'single' : {
    	init: function () {
    		if ($('.topmast_slider_holder').length) {
    			$('.banner_cta').each(function () {
    				$(this).click(function () {
        				$(this).slideUp();
        				$(this).closest('.banner_cta_holder').find('.banner_form').slideDown();
        			});
    			});
    			$('.banner_form h3').each(function () {
    				$(this).click(function () {
    					$(this).closest('.banner_cta_holder').find('.banner_cta').slideDown();
        				$(this).closest('.banner_cta_holder').find('.banner_form').slideUp();
    				});
    			});
    		}
    	}
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

/* This function is called by the setting section on the contact-form 7's gated docs form. */
function get_me_the_doc () {
	if (!document.getElementById('itemid')) {
		return;
	}
	var d = new Date();
	d.setDate(d.getDate()+1);
	document.cookie = 'mayproceed2doc=1; expires=' + d.toGMTString() + '; path=/';
	var docurl	= document.getElementById('itemid').value;
	location 	= docurl;
}



function inform_crm() {
    /* Set contact form 7 to send lead data to the CRM.
    * URL Sample: https://crmplugin.weboxcloud.com/shafirsystems_Lead/leadapi.aspx?token=20171109&new_temp1=michal&new_temp2=mycompany&new_temp3=43534435&new_temp4=my@email.com&redirectUrl=http://www.matrix-cabinet.com/thank-you/&new_temp7=website&new_temp8=testest test comments
    *
    * */
    document.addEventListener( 'wpcf7mailsent', function( event ) {
    	var current_form = event.target;
    	/* 1. If the form has an input named 'go2crm' with the value'true'  */
        if ($(current_form).find('#go2crm').length > 0 ) {
        	if ('1' == $('#go2crm').val()) {
				var crm_url = 'https://crmplugin.weboxcloud.com/shafirsystems_Lead/leadapi.aspx?token=20171109';
				var page_redirect = 'http://www.matrix-cabinet.com/thank-you/';
				var my_form_elements = $(current_form).find(':input');
				var new_temp1;
				var new_temp4;
				var new_temp2;
				var new_temp9;
				var new_temp3;
				var new_temp8;
				var redirectUrl = page_redirect;
				for (var i = 0;i < my_form_elements.length;i++) {
					current_field = my_form_elements[i];
					switch (current_field.getAttribute('name')) {
						case 'your-name':
							new_temp1 = current_field.value;
							break;
						case 'your-email':
							new_temp4 = current_field.value;
							break;
						case 'company':
							new_temp2 = current_field.value;
							break;
						case 'address':
							new_temp9 = current_field.value; //Not requested by the API
							break;
						case 'phone':
							new_temp3 = current_field.value; //Contact form
							break;
						case 'your-phone':
							new_temp3 = current_field.value; //Landing page
							break;
						case 'tel-500':
							new_temp3 = current_field.value; //Solution/Sector page
							break;
						case 'your-message':
							new_temp8 = current_field.value; //Landing page
							break;
						case 'your-comment':
							new_temp8 = current_field.value; //Contact form
							break;
						case 'textarea-407':
							new_temp8 = current_field.value; //Solution/Sector page
							break;
						case 'page-redirect':
							if (current_field.value != '') {
								redirectUrl = current_field.value;
							}
							break;
					}
				}
				var action_url = crm_url + '&new_temp1=' + new_temp1 + '&new_temp2=' + new_temp2 + '&new_temp3=' + new_temp3 + '&new_temp4=' + new_temp4 + '&redirectUrl=' + redirectUrl + '&new_temp7=website&new_temp8=' + new_temp8;
				location = action_url;
            }
            else  if ($(current_form).find('#page-redirect').length > 0) {
                location = $('#page-redirect').val();
			}
			console.log(location);
        }
        else if ($(current_form).find('#gated').length > 0 && '1' == $('#gated').val() ) {
            get_me_the_doc();
		}
    });
}